import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import details1 from '../../assets/images/projects/c2s.jpg'
import project2 from '../../assets/images/projects/project2.jpg'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Cheese2School" 
                homePageText="APP development" 
                homePageUrl="/app-development" 
                activePageText="Cheese2School" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Partner:</span>
                        <a href="https://www.ciai.it" target="_blank" rel="noreferrer">CIAI ONLUS</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        Burkina Faso
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Technology:</span>
                        NodeJS + Angular
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-money'></i>
                        </div>
                        <span>Donor:</span>
                        AICS
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-time'></i>
                        </div>
                        <span>Duration:</span>
                        3 anni
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Status</span>
                            On going
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Sviluppo APP</span>
                            <h3>The project</h3>
                            <p>In Burkina Faso in rural villages primary schools, if they exist, are overcrowded (more than 50 child per class). Classrooms are often not enough,  and several classes are combined in the same classroom.   
School attendance is collected manually.  There is no structured and analytical sharing system.  
We developed for CIAI an application for identifying correctly the male or female gender of children inside a classroom using a cheap smartphone camera without internet connection.  
Face recognition is an artificial intelligence technique, used in biometrics to identify or verify a person's identity from one or more images. We developed one specific tool to improve and make the attendance register more reliable the tool is called “Cheese2school - c2s”,  and allows to register students school attendance with a simple photo or short video of the class. The teacher takes a photo, the app recognises students and registers their presence.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-md-12">
                                <p>The tool offers two main features:
                                        <ul>
                                            <li>School attendance data collection: available through the smartphone Android application</li>
                                            <li>Monitoring: via the web platform.</li>
                                        </ul>
                                </p>
                                </div>
                            </div>
                            <p>The smartphone application allows teachers to speed up the collection of school presences and make the process more effective.
About 2000 photographs were collected during our first mission in Burkina Faso, using two digital cameras: one for females, and one for males. In this way we were sure to make a classification already at the origin.
In the second mission, in November 2019, we held training for all the stakeholder involved in the project, the teachers who collect data with the mobile app, and the mobilizers who will monitor data through the web app.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}

